import React from "react";

function JoinTheTeam({ jobsData }) {
  const jobsListItems = jobsData.jobs.map((job, internal_job_id) => {
    const borderClass = internal_job_id > 0 ? `bloom-border-t` : ``;
    const spacingClass = internal_job_id > 0 ? `bloom-mt-4 bloom-pt-4` : `bloom-pt-4`;
    const key = internal_job_id;

    return (
      <li
        className={`bloom-flex bloom-border-green ${borderClass} ${spacingClass}`}
        key={key}
      >
        <div className={`bloom-flex bloom-flex-grow`}>
          <a
            className={`bloom-text-xl bloom-font-bold hover:bloom-text-green hover:bloom-underline bloom-mr-2`}
            href={job.absolute_url}
            target="_blank"
          >
            {job.title}

          </a>
          <div className={`bloom-text-xl bloom-mr-2`}>
            {job.metadata.map((employment_type) => {
              return (
                employment_type.value
              )
            })}
          </div>
        </div>
        <div className={`bloom-flex`}>
          <div className={`bloom-text-xl`}>
            {job.location.name}
          </div>
        </div>
      </li>
    );
  });


  if(jobsData && jobsListItems.length) {
    return (
      <span>
        {jobsData &&
          <ul>
            {jobsListItems}
          </ul>}
      </span>
  );
  } else { 
    return (
      <span>Sorry! We don't have any jobs posted right now, but check back here in the future.</span>    
  );
  }

}

export default JoinTheTeam;
