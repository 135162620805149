import React from "react";
import Value from "./value";
import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";


function Values({ values, vision }) {

let contentHTML = remark()
   .use(recommended)
   .use(remarkHtml)
   .processSync(vision.content)
   .toString();

  return (
    <>
      <div className={`bloom-flex bloom-pt-6`}>
        <h2
          className={`bloom-text-4xl bloom-uppercase bloom-text-grey-darkest bloom-font-bold bloom-mb-5 bloom-border-b-4 bloom-border-green`}
        >
          Our Values
        </h2>
      </div>
      <div className={`bloom-values-grid bloom-text-xl bloom-help bloom flex bloom-pt-4`}>
        {values.map((value, index) => {
           return <Value count={index} key={index} value={value} />;
         })}
      </div>
      <div className={`bloom-mb-12 bloom-text-xl bloom-help bloom-pt-8`} dangerouslySetInnerHTML={{ __html: contentHTML  }}/>
    </>
  );
}

export default Values;
