import React, { useState } from "react";

import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";

function Value({ value }) {
  let contentHTML = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(value.content)
    .toString();

  let titleHTML = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(value.title)
    .toString();

  return (
    <div>
      <h3
        className={`bloom-text-2xl bloom-font-bold bloom-leading-tight bloom-text-green`}
        dangerouslySetInnerHTML={{ __html: titleHTML }}
      />
      <div
        className={`bloom-text-xl bloom-help`}
        dangerouslySetInnerHTML={{ __html: contentHTML }}
      />
    </div>
  );
}

export default Value;
