import React from "react";
import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";


function Benefits({ benefits }) {

let contentHTML = remark()
          .use(recommended)
          .use(remarkHtml)
          .processSync(benefits.content)
          .toString();

return (
    <>
      <div className={`bloom-flex`}>
        <h2 className={`bloom-text-4xl bloom-uppercase bloom-text-grey-darkest bloom-font-bold bloom-mb-5 bloom-border-b-4 bloom-border-green`} >
            Our Benefits
        </h2>
      </div>
      <div className={`bloom-flex`}>
        <div
          className={`bloom-text-xl bloom-pt-4`}
        >
          {benefits.description}
        </div>
      </div>
      <div
        className={`bloom-prose bloom-text-xl bloom-benefits-grid bloom-pt-4 bloom-mb-4`}
        dangerouslySetInnerHTML={{ __html: contentHTML }}
      />
    </>
  );
}


export default Benefits;