import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Block from "../components/block";
import JoinTheTeam from "../components/jointheteam";
import Values from "../components/values";
import Benefits from "../components/benefits";
import WhatWeDo from "../components/whatWeDo"
import Subfooter from "../components/subfooter";


function JobsPage({location, data}) {
  const content = data.markdownRemark.frontmatter;
  const values = content.values_block
  const vision = content.vision
  const benefits = content.benefits
  const work = data.work.frontmatter.projects

  const [jobsData, setJobsData] = useState({jobs: [], meta: {total: 0}});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchJobBoardData = () => {
    fetch(`https://boards-api.greenhouse.io/v1/boards/bloomworks/jobs`)
      .then(response => response.json())
      .then(
        (jobsData) => {
        setLoading(false);
        setJobsData(jobsData);
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        setLoading(false);
        setError(error)
      });
    }

  useEffect(() => {
    fetchJobBoardData()
    }, []);

return (
  <Layout className={`bloom-flex`} location={location}>
    <SEO keywords={[`bloom`, `bloom works`]} title="Bloom Works" />
      <Block>
        <div className={`bloom-ht`}>{content.hero.title}</div>
        <p className={`bloom-text-2xl`}>{content.hero.subTitle}</p>
      </Block>
      <Block className={`bloom-mb-20 bloom-mt-20 bloom-bg-white`}>
        {loading ? (
        <p className={`bloom-text-2xl`}>Loading ...</p>
          ) : (
            <div className={`bloom-mb-20 bloom-mr-4`}>
              <div className={`bloom-mb-20`}>
                <div className={`bloom-flex bloom-mt-20`}>
                  <h2
                    className={`bloom-text-4xl bloom-uppercase bloom-text-grey-darkest bloom-font-bold bloom-mb-5 bloom-border-b-4 bloom-border-green`}
                    id={`joinbloom`}
                  >
                    Join the Team
                  </h2>
                </div>
                <JoinTheTeam jobsData={jobsData}/>
              </div>
            </div>
          )}
        <Values values={values} vision={vision}/>
        <Benefits benefits={benefits}/>
      </Block>
        <WhatWeDo work={work} />
    <Subfooter />
  </Layout>
  )
}

export const jobssPageQuery = graphql`
  query JobsTemplate {
    markdownRemark(frontmatter: {templateKey: {eq: "jobs"}}) {
      html
      frontmatter {
        hero {
          title
          subTitle
        }
        values_block {
          content
          title
        }
        vision {
          content
        }
        benefits {
          description
          content
        }
      }
    }
    images: markdownRemark(frontmatter: { templateKey: { eq: "team" }}) {
      html
      frontmatter {
        people {
          person {
            name
            title
            image {
              childImageSharp {
                fluid(
                  maxWidth: 800
                  maxHeight: 800
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    work: markdownRemark(frontmatter: { templateKey: {eq: "work"}}) {
    frontmatter {
      projects {
          project {
            image {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            client
            description
            title
            linkTitle
            linkURL
          }
        }
    }
  }
  }
`;

export default JobsPage;