import React from "react";
import BackgroundImage from "gatsby-background-image";

function WhatWeDo({ work }) {

  return (
    <div className={`bloom-max-w-4xl bloom-container bloom-mx-auto md:bloom-py-14`}>
         <div className={`bloom-flex`}>
              <h2 className={`bloom-text-4xl bloom-uppercase bloom-text-grey-darkest bloom-font-bold bloom-mb-8 bloom-border-b-4 bloom-border-green`} >
                  What We Do
              </h2>
         </div>
         <div className={`bloom-flex bloom-flex-wrap bloom--mx-2 bloom-mb-12`}>
          {work.project &&
           work.project.length &&
           work.project.slice(0,3).map((project, index) => {
            return (
              <div
                key={index}
                className={`bloom-flex bloom-w-full md:bloom-w-1/2 lg:bloom-w-1/3 bloom-p-2 bloom-mb-4`}
              >
                <a
                  className={`bloom-card bloom-flex bloom-flex-col bloom-w-full`}
                  href={project.linkURL}
                >
                  <div
                    className={`bloom-h-56 bloom-w-full bloom-bg-seafoam bloom-mb-2`}
                  >
                    {project.image && (
                      <BackgroundImage
                        backgroundColor={`transparent`}
                        className={`bloom-h-56 bloom-w-full`}
                        fluid={project.image.childImageSharp.fluid}
                        Tag="section"
                      ></BackgroundImage>
                    )}
                  </div>
                  <div className="bloom-p-6" >
                    <h3
                      className={`bloom-text-2xl bloom-font-bold bloom-leading-tight bloom-uppercase bloom-mb-1`}
                    >
                      {project.title}
                    </h3>
                    <div
                      className={`bloom-font-bold bloom-text-green bloom-text-lg bloom-leading-tight bloom-mb-2`}
                    >
                      {project.client}
                    </div>
                    <div className={`bloom-prose bloom-mb-2`}>
                      {project.description}
                    </div>
                    <div className={`bloom-link`}>{project.linkTitle}</div>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
  )
}

export default WhatWeDo;